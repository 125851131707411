<template>
<div class="container1150">
<header><strong>陶瓷透水砖</strong></header>
<main>
<p>
                陶瓷透水砖为纯色系列，免烧结成型，表面致密，面层通过添加超亲水添加剂实现快速透水，具有透水、滤水等功能；主要应用于城市道路、停车场及公园道路等非机动车道
            </p>

<h2>产品特性</h2>
<p>● · 透水性能极佳</p>
<p>● · 强度高</p>
<p>● · 不风化、不褪色、不泛霜</p>
<p>● · 防滑性好、耐磨能力强、耐脏易清洗</p>
<p>● · 产品寿命长，可重复循环使用、维护成本低</p>
<p>● · 色彩丰富、装饰性能强</p>
<div class="img-list">
<div class="img1"></div>
<div class="img2"></div>
</div>
<div class="img3"></div>
<div class="img-list2">
<div class="img1"></div>
<div class="img2"></div>
</div>
</main>
</div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        // .img1 {
        //     // width: 100%;
        //     width: 550px;
        //     height: 400px;
        //     margin: 10px auto;
        //     background-image: url("/img/product/2-1.jpg");
        //     background-repeat: no-repeat;
        //     background-size: 100% 100%;
        // }
        .img-list {
            display: flex;
            width: 100%;
            .img1 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/4-1.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img2 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/4-2.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        .img3 {
            width: 1100px;
            height: 600px;
            margin: 10px auto;
            background-image: url("/img/product/4-3.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img-list2 {
            display: flex;
            width: 100%;
            .img1 {
                // width: 100%;
                width: 390px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/4-4.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img2 {
                // width: 100%;
                width: 390px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/4-5.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
    }
}
</style>